export const sendProductGameVenderLobbyPiwik = {
    launchGameByImage: (gameCatCode, providerCode) => {
        Pushgtagdata(
            `${gameCatCode}_Listing`,
            `Launch ${providerCode}`,
            `${gameCatCode}_Listing_C_${providerCode}`
        );
    },
    launchGameByBtn: (gameCatCode, providerCode) => {
        Pushgtagdata(
            `${gameCatCode}_Listing`,
            `Launch ${providerCode}`,
            `${gameCatCode}_Listing_C_${providerCode}_Comercial`
        );
    },
};
export const sendProductGameLobbyPiwik = {
    jackpot: (gameCatCode, providerCode, gameName) => {
        Pushgtagdata(
            `${gameCatCode}_Listing`,
            `Launch Game ${gameName}`,
            `${gameCatCode}_Listing_C_${providerCode}_Jackpot_Game`,
            false,
            [
                {
                    customVariableKey: `${gameCatCode}_Listing_C_${providerCode}_Jackpot_GameName`,
                    customVariableValue: gameName,
                },
            ]
        );
    },
};
export const sendProductGameListPiwik = {
    platform: (gameCatCode, providerCode) => {
        Pushgtagdata(
            `${gameCatCode}_Lobby`,
            "Filter Vendor",
            `${gameCatCode}_Lobby_C_${providerCode}`
        );
    },
};
export function setOtpPiwikName(Type) {
    let name = "";
    switch (Type) {
        case "login-otp":
            name = "OTP";
            break;
        case "withdrawal-otp":
            name = "Withdrawal";
            break;
    }
    return name;
}
export function storePagePiwikData(url, title) {
    const pageData = {
        url: url || "",
        title: title || "",
    };
    sessionStorage.setItem("currentPagePiwikData", JSON.stringify(pageData));
}
export function getPagePiwikData() {
    const storedData = sessionStorage.getItem("currentPagePiwikData");
    return storedData ? JSON.parse(storedData) : null;
}
export function clearPagePiwikData() {
    sessionStorage.removeItem("currentPagePiwikData");
}
